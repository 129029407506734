<template>
  <div class="order_comments">
    <div class="comments_top_box">
      <div
        class="back"
        @click="
          $router.push({
            name: 'OrderCenter',
            params: {
              page: page * 1
            }
          })
        "
      >
        ﹤ 订单中心
      </div>
      <div class="rigth_box">
        <div class="serial_box">
          <div class="serial_text">
            订单编号: <span> {{ itemObj.orderNumber }} </span>
          </div>
        </div>
        <div class="date_box">{{ itemObj.createTime }}</div>
      </div>
    </div>

    <div class="subject_box">
      <div class="left_box">
        <img
          class="img"
          :src="
            goodsarr.productFiles
              ? $store.state.onlineBasePath + goodsarr.productFiles
              : require('assets/images/no-shop-img80.png')
          "
          alt=""
        />
        <el-tooltip
          class="item"
          :content="goodsarr.productName"
          placement="bottom-start"
        >
          <div class="name_box">{{ goodsarr.productName }}</div>
        </el-tooltip>
      </div>
      <div class="rigth_box">
        <div class="score_box">
          <span>星级评分:</span>
          <el-rate v-model="rate"></el-rate>
        </div>
        <div class="textarea_box">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="写下评价吧,你的评价对其他卖 家有很大的帮助。"
            v-model="text"
          >
          </el-input>
        </div>

        <div class="up_box">
          <el-upload
            class="upload-demo"
            action="http://localhost:8080/api/common/upload"
            :on-success="handleSuccess"
            :on-remove="handleRemove"
            :multiple="true"
            :file-list="fileList"
            list-type="picture-card"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG"
            :limit="9"
          >
            <img class="img" src="../../../../assets/images/upimg.png" alt="" />

            <div slot="tip" class="el-upload__tip">
              共
              <span>{{ fileList.length }}</span> 张，还能上传
              <span>{{ 9 - fileList.length }}</span> 张
            </div>
          </el-upload>
        </div>
      </div>
    </div>
    <div class="btn_box">
      <div class="btn">
        <el-button @click="release" type="danger">发布评论</el-button>
        <!-- <el-checkbox v-model="checked">匿名评价</el-checkbox>-->
      </div>
    </div>
  </div>
</template>

<script>
import { add } from "api/copyrightService";
export default {
  name: "OrderComments",
  data() {
    return {
      // 订单
      itemObj: JSON.parse(this.$route.query.item),
      page: this.$route.query.page,
      //所有商品
      goodsarr: JSON.parse(this.$route.query.item).productInfos[0],
      // 数据数组
      listArr: [],
      // 匿名评价
      checked: false,
      //评价内容
      text: "",
      //默认星级
      rate: 5,
      //上传数据
      fileList: [],
      //评论图片
      imgList: []
    };
  },

  methods: {
    //发布评论
    async release() {
      //组合上传图片
      for (var a of this.fileList) {
        this.imgList.push(a.response.data);
      }

      console.log(this.itemObj);
      let data = {
        productId: this.itemObj.productId,
        score: this.rate,
        content: this.text,
        evalvateImg: this.imgList.toString(),
        orderId: this.itemObj.orderId
      };
      let res = await add(data);
      if (res.code == 200) {
        this.$router.push({
          name: "OrderCenter"
        });
        this.$message.success("发布成功");
      } else {
        this.$message.error(res.message);
      }
    },
    //上传成功
    handleSuccess(response, file, fileList) {
      this.fileList = fileList;
    },
    //删除上传
    handleRemove(file, fileList) {
      this.fileList = fileList;
    }

    // handleIndex(index) {
    // 	// console.log(index);
    // 	this.arrIndex = index;
    // },
    // handleSuccess(response, file, fileList) {
    // 	let arr = [];
    // 	arr.push(file.response.data);

    // 	this.listArr.fileList = [
    // 		...this.listArr.fileList,
    // 		...arr,
    // 	];

    // 	let Length = [...this.Length];
    // 	Length = fileList.length;

    // 	this.Length = Length;
    // },

    // handleRemove(file, fileList) {
    // 	// console.log(fileList);
    // 	let arr = [];
    // 	fileList.forEach((item) => {
    // 		arr.push(item.response.data);
    // 	});

    // 	this.listArr.fileList = arr;
    // 	let Length = [...this.Length];
    // 	Length = fileList.length;

    // 	this.Length = Length;
    // },

    // handlelist() {
    // 	let itemlist = JSON.parse(this.$route.query.item).productInfos;
    // 	let arr = [];
    // 	arr = itemlist.map((item) => {
    // 		item["fileList"] = [];

    // 		return item;
    // 	});

    // 	this.listArr = arr;
    // },
  },
  created() {}
};
</script>

<style lang="scss">
.el-tooltip__popper {
  background: #fff !important;
  color: #999 !important;
  border: 1px solid #aaa !important;
}
</style>
<style lang="scss" scoped>
.order_comments {
  .comments_top_box {
    margin-left: 20px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .back {
      cursor: pointer;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }

    .rigth_box {
      margin-right: 20px;
      display: flex;
      font-size: 12px;

      .serial_box {
        color: #999999;

        span {
          color: #333333;
        }

        margin-right: 20px;
      }

      .date_box {
        color: #999999;
      }
    }
  }

  .subject_box {
    display: flex;

    .left_box {
      border-right: 1px solid #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 310px;
      box-sizing: border-box;
      padding: 0 40px;

      .img {
        width: 90px;
        height: 90px;
      }

      .name_box {
        text-align: center;
        margin-top: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 210px;
      }
    }

    .rigth_box {
      flex: 1;
      padding: 40px 130px 40px 40px;

      .score_box {
        display: flex;

        span {
          margin-right: 10px;
        }
      }

      .textarea_box {
        margin: 20px 0;
      }

      .up_box {
        .upload-demo {
          display: flex;
          align-items: center;

          ::v-deep .el-upload-list {
            .el-upload-list__item {
              width: 40px;
              height: 40px;
              margin: 0 10px 0 0;
              border: none;
              border-radius: 0;

              .el-upload-list__item-status-label {
                display: none;
              }

              .el-upload-list__item-delete {
                margin: auto;
              }

              .el-upload-list__item-preview {
                display: none;
              }
            }
          }

          ::v-deep .el-upload--picture-card {
            background-color: none;
            border: none;
            border-radius: none;
            box-sizing: none;
            width: auto;
            height: auto;
            line-height: 0;
            vertical-align: none;
          }
        }

        .el-upload {
          .img {
            width: 40px;
            height: 40px;
          }
        }

        .el-upload__tip {
          margin-top: 0px;
          margin-left: 10px;

          span {
            color: red;
          }
        }
      }
    }
  }

  .btn_box {
    margin-top: 50px;

    .btn {
      width: 40%;
      margin: 30px auto;

      .el-button--danger {
        background-color: red;
        font-weight: 800;
        font-size: 16px;
        width: 220px;
        height: 48px;
        margin-right: 40px;
      }
    }
  }
}
</style>
